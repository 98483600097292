<template>
  <v-icon
    v-clipboard:copy="token"
    v-clipboard:success="onCopy"
    style="cursor: pointer"
    color="info darken-3"
    class="mr-2"
    @click="handleClick"
  >
    mdi-content-copy
  </v-icon>
</template>
  
  <script>
export default {
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  methods: {
    onCopy() {
      this.$emit("copied", this.token);
    },
    handleClick() {
      this.$emit("open-dialog", this.token);
    },
  },
};
</script>
  